/*
  WARNING: This file is a pit of despair.
  It builds a tree of what nav items the user should see - 
  and does some reversal of that tree in order to figure out which section needs to be expanded.
  The types which have been applied should *not* be trusted - they are the bare minimum to get TS to be happy. 
*/

import get from 'lodash/get';

import { Event } from '@/domain';
import { CustomDomainsShowPage } from '@/utils/flags';

import { Routes } from '../../routes';
import {
  eventHasSimplifiedNav,
  getNavTreeByEventWizardTemplate,
} from './constants';
import {
  ConfigProps,
  EventWizardTemplate,
  NavTreeShape,
  TemplateModifier,
  VenueType,
} from './types';

function getConfig({
  event: {
    anonymised,
    areas,
    slug,
    organization,
    venueType,
    primaryBackstageId,
    session,
    isLite,
  },
  isAppAreaVisible,
  newRegistrationsUrl,
  newRegistrationsDashboardEnabled,
  creationEnableGlobalTags,
  surveyBuilderEnabled,
  ticketGroupsDeprecated,
  showEngagement,
  engagementPageMigrated,
  communicationsPageMigrated,
  announcementsPageMigrated,
  onsiteEventDashboardStreamline,
}: ConfigProps) {
  const { currentPaymentPlan, maxStages, hasAnalytics } = organization;
  const features = currentPaymentPlan.features;
  const isOnsite = venueType === 'onsite';
  const attendeesPath = newRegistrationsDashboardEnabled
    ? Routes.organisersEventNewAttendeesPath(slug).as
    : Routes.organisersEventAttendeesPath(slug).as;

  const config = {
    overview: {
      route: Routes.organisersEventOverviewPath(slug).as,
      active: true,
    },
    /**
     * When Essentials was developed, we designed a separate Basics page for it,
     * and therefore we ended up with two different Basics pages (/basics and /setup).
     * The plan for the future is to have one Basics page to serve Virtual Events and Lite Events
     */
    basics: {
      route: isLite
        ? Routes.organisersEventSetupPath(slug).as
        : Routes.organisersEventBasicsPath(slug).as,
      active: true,
    },
    theme: {
      route: Routes.designOrganisersEventPath(slug).as,
      active: true,
    },
    registration: {
      route: Routes.registrationOrganisersEventPath(slug).as,
      active: !newRegistrationsDashboardEnabled,
    },
    // registrations
    'ticket-groups': {
      route: newRegistrationsUrl + '/event-tracks?slug=' + slug,
      active: newRegistrationsDashboardEnabled && !ticketGroupsDeprecated,
    },
    'registrations-tickets': {
      route: newRegistrationsUrl + '/tickets?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    'registration-form': {
      route: newRegistrationsUrl + '/form-builder?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    'registration-landing-page': {
      route: Routes.organiserRegistrationEventLandingPagePath(slug).as,
      active: newRegistrationsDashboardEnabled,
    },
    details: {
      route: newRegistrationsUrl + '/details?slug=' + slug,
      active: newRegistrationsDashboardEnabled,
    },
    // venue
    reception: {
      route: Routes.receptionFormOrganisersEventPath(slug).as,
      active: areas.reception,
    },
    schedule: {
      route: Routes.organisersEventSchedulesPath(slug).as,
      active: true,
    },
    stage: {
      route: Routes.editOrganisersEventBackstagePath(slug, primaryBackstageId)
        .as,
      active: areas.stage && maxStages <= 1,
    },
    stages: {
      route: Routes.organisersEventBackstagesPath(slug).as,
      active:
        onsiteEventDashboardStreamline && isOnsite
          ? false
          : areas.stage && maxStages > 1,
    },
    session: {
      route: Routes.editOrganisersEventTimetableRoundtablePath({
        id: session?.id || 0,
        timetableId: session?.eventPartId || 0,
        slug,
      }).as,
      active: areas.sessions && isLite && session !== null,
    },
    sessions: {
      route: Routes.organisersEventRoundtablesPath(slug).as,
      active:
        onsiteEventDashboardStreamline && isOnsite
          ? false
          : areas.sessions && !isLite,
    },
    'venue-controls-people': {
      route: Routes.peopleOrganisersEventPath(slug).as,
      active: areas.people,
    },
    surveys: {
      route: Routes.organisersEventSurveysPath(slug).as,
      active: surveyBuilderEnabled,
    },
    networking: {
      route: Routes.networkingOrganisersEventPath(slug).as,
      active: !areas.people && areas.networking,
    },
    expo: {
      route: Routes.organisersEventVendorsPath(slug).as,
      active: areas.expo,
    },
    sponsors: {
      route: Routes.organisersEventSponsorsPath(slug).as,
      active: true,
    },
    tags: {
      route: Routes.organisersEventEventTagsPath(slug).as,
      active: creationEnableGlobalTags,
    },
    'venue-controls': {
      route: Routes.organisersEventVenueControlsPath(slug).as,
      active: true,
    },
    'app-area': {
      route: Routes.organisersEventAppAreaPath(slug).as,
      active: areas.app && isAppAreaVisible,
    },
    // onsite
    ...onsiteMenu(slug, venueType, features),
    // people
    registrants: {
      route: attendeesPath,
      active: true,
    },
    speakers: {
      route: Routes.organisersEventSpeakersPath(slug).as,
      active: true,
    },
    'magic-link-invites': {
      route: Routes.organisersEventRedeemCodesPath(slug).as,
      active: features?.magicLinks,
    },
    // marketing
    'email-attendees': {
      route: Routes.organisersEventAnnouncementsPath(slug).as,
      active: !announcementsPageMigrated && features?.sendAttendeesEmails,
    },
    'customize-emails': {
      route: Routes.organisersEventCommunicationsPath(slug).as,
      active: !communicationsPageMigrated && features?.customEmails,
    },
    emails: {
      route: Routes.organisersEmailsPath(slug).as,
      active:
        (announcementsPageMigrated && features?.sendAttendeesEmails) ||
        (communicationsPageMigrated && features?.customEmails),
    },
    'sharing-and-tracking': {
      route: Routes.organisersEventMarketingPath(slug).as,
      active: true,
    },
    // advanced
    'customize-text': {
      route: Routes.textOrganisersEventPath(slug).as,
      active: features?.customText,
    },
    'custom-registration-fields': {
      route: Routes.organisersEventRegistrationFieldsPath(slug),
      active:
        !newRegistrationsDashboardEnabled && features?.customRegistrationFields,
    },
    'host-information': {
      route: Routes.organisersEventHostInformationPath(slug).as,
      active: features?.hostDefinitions,
    },
    // old page
    'custom-domain': {
      route: Routes.organisersEventCustomDomainPath(slug).as,
      active: features?.customDomains,
    },
    // new page
    'custom-domains': {
      route: Routes.organisersEventCustomDomainsPath(slug).as,
      active: features?.customDomains,
    },
    recordings: {
      route: Routes.organisersEventRecordingsPath(slug).as,
      active: true,
    },
    // analytics
    engagement: {
      route: engagementPageMigrated
        ? Routes.organisersEventEngagementSummaryPath(slug).as
        : Routes.organisersEventConnectionsSummaryPath(slug).as,
      active: !anonymised && (showEngagement || engagementPageMigrated),
    },
    'live-analytics': {
      route: Routes.organisersEventLiveAnalyticsPath(slug).as,
      active: !anonymised && hasAnalytics,
    },
    reports: {
      route: Routes.organisersEventReportsPath(slug).as,
      active: !anonymised,
    },
    registrations: {
      route: Routes.organisersEventRegistrationsSummaryPath(slug).as,
      active: !anonymised,
    },
    connections: {
      route: Routes.organisersEventConnectionsSummaryPath(slug).as,
      active: !anonymised && !(showEngagement || engagementPageMigrated),
    },
    'view-polls': {
      route: Routes.organisersEventEventPollsPath(slug).as,
      active: !anonymised,
    },
    'expo-summary': {
      route: Routes.organisersEventExpoSummaryPath(slug).as,
      active: !anonymised && areas.expo,
    },
    'utm-codes': {
      route: Routes.organisersEventUtmCodesPath(slug).as,
      active: !anonymised,
    },
  };

  return config;
}

const hasSubItems = (navItem: Record<string, any>) => {
  const supportingFields = ['__title'];

  const numberOfSupportingFields = Object.keys(navItem).filter(key =>
    supportingFields.includes(key),
  ).length;

  return Object.keys(navItem).length - numberOfSupportingFields > 0;
};

function link(tree: Record<string, any>, config: Record<string, any>) {
  return Object.keys(tree).reduce<Record<string, any>>((acc, item) => {
    const children = tree[item];
    const navItem = config[item];

    const hasNestedChildren = hasSubItems(children);

    if (hasNestedChildren) {
      const itemChildren = link(children, config);
      if (Object.keys(itemChildren).length) {
        acc[item] = {
          children: itemChildren,
          parent: acc,
          title: item,
        };
      }
    } else if (navItem?.active) {
      acc[children.__title || item] = navItem.route;
    }

    return acc;
  }, {});
}

function makeReverseMap(obj: any, parentKey: string[] = []): any {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (k === 'parent') {
      return acc;
    }
    if (v instanceof Object) {
      return { ...acc, ...makeReverseMap(v, [...parentKey, k]) };
    }
    if (typeof v === 'string') {
      const parentNode = [...parentKey, k];
      return { ...acc, [v]: parentNode };
    }
  }, {});
}

function resolveException(path: string, reverseMap: any, config: any) {
  const exceptions: [RegExp, any][] = [
    [
      /organisers\/events\/[\w\d-]+\/schedule\/[\d]+\/roundtables/,
      config.sessions,
    ],
    [/organisers\/events\/[\w\d-]+\/reports/, config.reports],
    [/organisers\/events\/[\d]+\/backstages/, config.stages],
    [/organisers\/events\/[\d]+\/speakers/, config.speakers],
  ];

  return exceptions.reduce((acc, [regExp, match]) => {
    if (regExp.test(path)) {
      return reverseMap[match];
    }
    return acc;
  }, null);
}

function resolveContext(locationObject: Location) {
  const { pathname, search } = locationObject;
  const params = new URLSearchParams(search);
  const context = params.get('context');
  if (context) {
    return `${pathname}/${context}`;
  }
  return pathname;
}

const getTemplateModifier = (event: Event): TemplateModifier => {
  let modifier: TemplateModifier = 'before';
  if (event.phase === 'Live') {
    modifier = 'during';
  }
  if (event.phase === 'Ended') {
    modifier = 'after';
  }
  return modifier;
};

export const getDomainPageEntry = (
  showCustomDomains?: CustomDomainsShowPage,
) => {
  switch (showCustomDomains) {
    case CustomDomainsShowPage.NONE:
      return {};
    case CustomDomainsShowPage.NEW:
      return { 'custom-domains': {} };
    case CustomDomainsShowPage.CLASSIC:
      return { 'custom-domain': {} };
    case CustomDomainsShowPage.BOTH:
      return {
        'custom-domain': {},
        'custom-domains': {},
      };
  }

  return {};
};

export const getTreeByTemplate = ({
  eventWizardTemplate,
  navigationView,
  event,
  reorderTabs,
  showCustomDomainsPage = CustomDomainsShowPage.CLASSIC,
  showSponsors = false,
}: {
  eventWizardTemplate: EventWizardTemplate | 'default' | string;
  navigationView: string;
  event: Event;
  reorderTabs: boolean;
  showCustomDomainsPage?: CustomDomainsShowPage;
  showSponsors?: boolean;
}) => {
  const modifier = getTemplateModifier(event);
  const templateTree = getNavTreeByEventWizardTemplate({
    template: eventWizardTemplate,
    modifier,
    reorderTabs,
    showSponsors,
    showCustomDomainsPage,
  });

  if (eventHasSimplifiedNav(eventWizardTemplate)) {
    return navigationView === 'advanced'
      ? getNavTreeByEventWizardTemplate({
          template: 'default',
          modifier,
          reorderTabs,
          showSponsors,
          showCustomDomainsPage,
        })
      : templateTree;
  }
  return templateTree;
};

function makeNavTree({
  event,
  isAppAreaVisible,
  creationEnableGlobalTags,
  surveyBuilderEnabled,
  newRegistrationsDashboardEnabled,
  newRegistrationsUrl,
  navigationView,
  ticketGroupsDeprecated,
  reorderTabs,
  showSponsors,
  showEngagement,
  showCustomDomainsPage,
  engagementPageMigrated,
  announcementsPageMigrated,
  communicationsPageMigrated,
  onsiteEventDashboardStreamline,
}: ConfigProps & {
  reorderTabs: boolean;
  showSponsors: boolean;
  showCustomDomainsPage: CustomDomainsShowPage;
}) {
  if (!event.slug) {
    return { tree: null, resolveTree: () => null };
  }
  const config = getConfig({
    event,
    creationEnableGlobalTags,
    surveyBuilderEnabled,
    isAppAreaVisible,
    navigationView,
    newRegistrationsDashboardEnabled,
    newRegistrationsUrl,
    ticketGroupsDeprecated,
    showEngagement,
    engagementPageMigrated,
    announcementsPageMigrated,
    communicationsPageMigrated,
    onsiteEventDashboardStreamline,
  });
  const { eventWizardTemplate } = event;

  const navTree = getTreeByTemplate({
    eventWizardTemplate,
    navigationView,
    event,
    reorderTabs,
    showCustomDomainsPage,
    showSponsors,
  });

  const tree: NavTreeShape = {
    parent: null,
    children: link(navTree, config),
  };

  const reverseMap = makeReverseMap(tree);

  const resolveTree = (
    locationObject: Location,
  ): {
    key: string;
    rootKey: string;
    tree: any;
  } => {
    const currentPath = resolveContext(locationObject);
    let reverse = reverseMap[currentPath];
    if (!reverse) {
      const splitPath = currentPath.split('/');
      for (let i = splitPath.length - 1; i > 0 && !reverse; i--) {
        const subPath = splitPath.slice(0, i).join('/');
        reverse = reverseMap[subPath];
      }
      if (!reverse) {
        reverse = resolveException(currentPath, reverseMap, config);
      }
    }
    const treePath = reverse?.slice(0, reverse.length - 2);

    // key: the current path, rootKey: the highest up in the tree that is active
    return treePath
      ? {
          tree: get(tree, treePath, tree),
          key: [...reverse].pop(),
          rootKey: reverse[1],
        }
      : { tree, key: '', rootKey: '' };
  };

  return { tree, resolveTree };
}

function onsiteMenu(
  slug: string,
  venueType: VenueType,
  features: Record<string, boolean>,
) {
  const menu = {};
  const isVirtualEvent = venueType === 'virtual';
  const isOnsiteEnabled =
    features?.onsiteHybridEvents || features?.onsiteAdvancedAddOn;

  if (isVirtualEvent || !isOnsiteEnabled) return menu;

  return {
    'check-in-areas': {
      route: Routes.organisersEventCheckInAreaPath(slug).as,
      active: features?.onsiteHybridEvents,
    },
    'kiosk-mode': {
      route: Routes.organisersEventKioskModePath(slug).as,
      active: features?.onsiteAdvancedAddOn,
    },
    badges: {
      route: Routes.organisersEventBadgeDesignerPath(slug).as,
      active: features?.onsiteAdvancedAddOn,
    },
    'floor-plan': {
      route: Routes.organisersEventFloorPlanPath(slug).as,
      active: features?.onsiteHybridEvents,
    },
    signatures: {
      route: Routes.organisersEventSignaturesPath(slug).as,
      active: features?.onsiteAdvancedAddOn,
    },
  };
}

export default makeNavTree;
