import Pusher, { Channel as PusherChannel } from 'pusher-js';
import { eventChannel } from 'redux-saga';

import { appLogger } from '../logger';
import { getAPIHost } from './host';

interface PusherCredentials {
  cluster: string;
  key: string;
}

export async function getPusherCredentials(): Promise<PusherCredentials> {
  try {
    const url = `${getAPIHost()}/api/v2/pusher/credentials`;
    const response = await fetch(url, {
      method: 'GET',
    });

    const { credentials } = await response.json();

    if (!credentials) {
      throw new Error('No credentials received'); // jumps to CATCH block below
    }

    return credentials;
  } catch (error) {
    console.error(error);
    console.log('Unable to fetch Pusher credentials from the server');

    return {
      cluster: import.meta.env.VITE_PUSHER_CLUSTER,
      key: import.meta.env.VITE_PUSHER_KEY,
    };
  }
}

/**
 * Note - Only public channels are currently supported!
 */
export async function connectToPusher() {
  const credentials = await getPusherCredentials();

  try {
    return new Pusher(credentials.key, {
      cluster: credentials.cluster,
    });
  } catch (error) {
    appLogger.error(`event dashboard: unable to connect to pusher`, {
      error,
    });
  }
}

export function subscribeToMessage(channel: PusherChannel, message: string) {
  return eventChannel(emit => {
    channel.bind(message, (data: unknown) => {
      emit(data);
    });
    return () => {
      channel.unsubscribe();
    };
  });
}

// Example usage
// const userChannel = pusher.subscribe(`users`);
// const newUsersEvents = yield* call(subscribeToMessage, userChannel, 'new-user');
// yield* takeLatest(newUserEvents, function* (user) { ... })
