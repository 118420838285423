import './index.css';
import './logger';

import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { initialiseStore } from './store';

Sentry.init({
  dsn: 'https://dff2a85cfc824a3584535c9c620a3d21@o370914.ingest.sentry.io/4504960532021248',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENV,
});

const { store, router } = initialiseStore();

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App router={router} store={store} />
  </StrictMode>,
);
